import React from 'react'
import Img from 'gatsby-image'

export default function Image({ image, alt, url, className, ...props }) {
  if (image === null) return null

  if (image.extension === 'gif' || image.extension === 'svg') {
    return (
      <div className={className}>
        <img src={`https://cms.portorocha.com${url}`} alt={alt} {...props} />
      </div>
    )
  }

  if (image.childImageSharp === null) {
    return null
  }

  if (image.childImageSharp.original) {
    return (
      <div className={className}>
        <img
          data-original
          src={image.childImageSharp.original.src}
          alt={alt}
          width={image.childImageSharp.original.width}
          height={image.childImageSharp.original.height}
          {...props}
        />
      </div>
    )
  }

  return (
    <Img
      fluid={image.childImageSharp.fluid}
      alt={alt}
      className={className}
      {...props}
    />
  )
}
